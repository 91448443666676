<template>
<v-container>
    <v-row>
        <v-col cols="12">
            <v-text-field :data-cy="`tableTo${node.demoNodeIndex}`" @focus="refreshCols()" color="primary" outlined dense :error="(errors?.tableTo)?true:false" v-model="data.step.tableTo" label="Step Name" :hint="errors.tableTo?.join(' - ')" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
        </v-col>
        <v-col cols="12" class="">

            <v-row class="py-0" v-for="(item, index) in data.step.filters" :key="index">

                <!-- column -->
                <v-col cols="3" class="py-0">
                    <v-select :data-cy="`column${node.demoNodeIndex}-${index+1}`" @focus="refreshCols()" outlined dense return-object label="Column" v-model='data.step.filters[index].column' :items="data.step.tableFromColumns" item-text="pretty_name" :hint="errors.column?.[index]?.join(' - ')" :error="(errors?.column?.[index])?true:false" @input="onChange(data.step.filters[index], 'column'); data.step.filters[index].value = null" persistent-hint></v-select>
                </v-col>

                <!-- word -->
                <v-col cols="2" class="py-0">
                    <v-select :data-cy="`operation${node.demoNodeIndex}-${index+1}`" @focus="generateFilterItems(data.step.filters[index].column.data_type_id)" outlined dense return-object label="Filter" v-model='data.step.filters[index].operator' :items="filterItems" :hint="errors.operator?.[index]?.join(' - ')" :error="(errors?.operator?.[index])?true:false" @input="onChange(data.step.filters[index], 'operator')" persistent-hint></v-select>
                </v-col>

                <!-- value - value -->
                <div cols="3" v-if="data.step.filters[index].type === 'value'">
                    <v-col class="py-0">
                        <!-- date -->
                        <div class="" v-if="data.step.filters[index].column.data_type_id === 3 && ['In the last X Days', 'Not in the last X Days',  'In the last X Months', 'Not in the last X Months'].includes(data.step.filters[index].operator) === false">
                            <v-menu @input="formatDate()" ref="menu" v-model="data.step.filters[index].menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="date" lazy transition="scale-transition" offset-y full-width min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field outlined dense :value="changeDisplayDateFormat(index)" label="Date" readonly v-on="on"></v-text-field>
                                   
                                </template>
                                <v-date-picker v-model='data.step.filters[index].value' no-title scrollable @input="data.step.filters[index].menu = false"></v-date-picker>
                            </v-menu>
                        </div>
                        <!-- text -->
                        <div v-if="data.step.filters[index].column.data_type_id !== 3 ||data.step.filters[index].column.data_type_id === 3  && ['In the last X Days', 'Not in the last X Days',  'In the last X Months', 'Not in the last X Months'].includes(data.step.filters[index].operator) === true">
                            <v-text-field :data-cy="`value${node.demoNodeIndex}-${index+1}`" outlined dense label="Value" v-model='data.step.filters[index].value' :error="(errors?.value?.[index])?true:false" :hint="errors.value?.[index]?.join(' - ')" @input="onChange(data.step.filters[index], 'value')" persistent-hint></v-text-field>
                        </div>
                    </v-col>
                </div>

                <!-- value - column -->
                <div cols="3" v-if="data.step.filters[index].type === 'column'">
                    <v-col class="py-0">
                        <v-select @focus="refreshCols()" outlined dense return-object label="Column" v-model='data.step.filters[index].value' :items="data.step.tableFromColumns.filter(column => column.data_type_id === data.step.filters[index].column.data_type_id)" item-text="pretty_name" :hint="errors.column?.[index]?.join(' - ')" :error="(errors?.column?.[index])?true:false" @input="onChange(data.step.filters[index], 'column')" persistent-hint></v-select>
                    </v-col>
                </div>

                <!-- switch -->
                <v-col cols="3" class="mt-n6">
                    <v-spacer></v-spacer>
                    <v-switch v-model="data.step.filters[index].type" hide-details true-value="value" false-value="column" :label="`${data.step.filters[index].type}`" @change="data.step.filters[index].value = null"></v-switch>
                </v-col>

                <!-- remove -->
                <v-col cols="1" class="">
                    <v-btn v-if="data.step.filters?.length > 1" color="primary" @click="remove(index)">
                        <v-icon>fa-thin fa-minus</v-icon>
                    </v-btn>
                </v-col>

                <!-- and/or -->
                <v-col cols="12" class="mb-n1 py-0 d-flex justify-start" v-if="index != data.step.filters.length-1">
                    <v-card-text v-if="data.step.andOr.length >= 1" class="text-overline pa-0 pl-1"><strong>{{data.step.andOr[index]}}</strong></v-card-text>
                </v-col>
                <v-col cols="12" class="mb-n7 pt-3 d-flex justify-start" v-if="index === data.step.filters.length-1">
                    <v-btn small class="mt-1" color="primary" @click="addAnother('And')">
                        <v-icon left>
                            fa-thin fa-ampersand
                        </v-icon>And
                    </v-btn>
                    <v-btn small class="mt-1 ml-2" color="primary" @click="addAnother('Or')">
                        <v-icon left>
                            fa-thin fa-arrow-down-arrow-up
                        </v-icon>Or
                    </v-btn>
                </v-col>

            </v-row>
        </v-col>
        <v-col cols="12" class="mt-5">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
        </v-col>

    </v-row>
</v-container>
</template>

<script>
import {
    emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'
import {
    userStore
} from '@/stores/UserStore';
import moment from "moment";


export default {
    name: 'WhereNode',
    mixins: [formRules, nodeCode],
    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup() {
        const user = userStore();
        return {
            user,
        }
        
    },

    data() {
        return {
            filterItems: [],
            menu: false,
            errors: {},
            data: {
                step: null,
                rules: {
                    tableTo: {
                        rule: 'minText'
                    },
                    column: {
                        rule: 'notEmptyObject'
                    },
                    operator: {
                        rule: 'notEmpty'
                    },
                    value: {
                        rule: 'notEmpty'
                    }
                },
            }
        }
    },

    created() {

        this.processParentData();

        this.data.step.type = 'filter'
        

        this.formatDate()

        for (var i = 0; i < this.data.step.filters.length; i++) {
          this.generateFilterItems(this.data.step.filters[i].column.data_type_id)
        }

    },
    methods: {
        formatDate(){
            
            for (var i = 0; i <  this.data.step.filters.length; i++) {
                console.log(this.data.step.filters[i].operator)
                if(this.data.step.filters[i].column.data_type_id === 3 && this.data.step.filters[i].value.indexOf('/') !== -1 && ['In the last X Days', 'Not in the last X Days',  'In the last X Months', 'Not in the last X Months'].includes(this.data.step.filters[i].operator) === false){
                    this.data.step.filters[i].value = moment(this.data.step.filters[i].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                }
            }
        },
        changeDisplayDateFormat(index){
            
            let x;
            if(this.data.step.filters[index].value === null){
                x =  null
            } else if(this.data.step.filters[index].value.indexOf('/') !== -1){
                x = this.data.step.filters[index].value
                //this.data.step.filters[index].value = moment(this.data.step.filters[index].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
            } else if(this.data.step.filters[index].value.indexOf('-') !== -1){
                //this.data.step.filters[index].value = moment(this.data.step.filters[index].value, 'YYYY-MM-DD').format('DD/MM/YYYY')
                x =  moment(this.data.step.filters[index].value, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }  else {
                x =  this.data.step.filters[index].value
            }
            
            
            return x
        },
        generateFilterItems(dataType) {

            switch (dataType) {
                case 1 || null:
                    this.filterItems = ['Equal To', 'Not Equal To', 'Less Than', 'Greater Than'];
                    break
                case 2 || null:
                    this.filterItems = ['Equal To', 'Not Equal To', 'Contains', 'Does Not Contain'];
                    break
                case 3 || null:
                    this.filterItems = ['On', 'Before', 'After','In the last X Days', 'Not in the last X Days',  'In the last X Months', 'Not in the last X Months'];
                    break
                case 4 || null:
                    this.filterItems = ['Is', 'Is Not'];
                    break
            }

        },

        disabledRules() {
            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                !this.data.step.newColumnName ||
                !this.data.step.frontendFormula ||
                !this.data.step.tableFromColumns
            ) {
                isDisabled = true
            }
            return isDisabled;
        },

        addAnother(item) {

            this.data.step.filters.push({
                column: {
                    data_type_id: null
                },
                operator: null,
                value: null,
                type: 'value'
            });

            this.data.step.andOr.push(item)

        },

        remove(index) {

            //this.data.step.filters.push(item);
            this.data.step.filters.splice(index, 1)
            this.data.step.andOr.splice(index - 1, 1)

        },
        emitDataAction() {

            for (var i = 0; i <  this.data.step.filters.length; i++) {
                if(this.data.step.filters[i].column.data_type_id === 3 && ['In the last X Days', 'Not in the last X Days',  'In the last X Months', 'Not in the last X Months'].includes(this.data.step.filters[i].operator) === false){
                    this.data.step.filters[i].value = moment(this.data.step.filters[i].value, 'YYYY-MM-DD').format('DD/MM/YYYY')
                }
            }


            this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns);

            emitData('emit-data', this.data); 

        }
    }

};
</script>
